.trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

    .trigger:hover {
        color: #1890ff;
    }

.logo {
    margin-top: 16px;
    margin-bottom: 16px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
}
