.content {
    max-width: 500px;
    margin: auto;
    padding: 10px;
    position: relative;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
